<script>
import AuthService from '@/service/auth.service';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'modal-invite-user',

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      form: {},
    };
  },

  created() {
    this.$on('create', this.create);
  },

  methods: {
    create() {
      this.form = {};
      this.loadingSave = false;
      this.dialog = true;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        await AuthService.sendInvite(form);

        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Convite enviado!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">Convidar novo usuário</span>
      </v-card-title>
      <v-card-text class="py-10">
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="form.name"
                label="Nome"
                color="white"
                autocomplete="nope"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="form.email"
                type="email"
                label="Email"
                color="white"
                autocomplete="nope"
                outlined
                persistent-placeholder
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Enviar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
