<script>
import AuthService from '@/service/auth.service';
import UsersService from '@/service/users.service';
import { UserRoleType } from '@/utils/enums/UserRoleType';
import { mapState } from 'vuex';

import About from '../About.vue';
import ModalRegisterUser from './ModalInviteUser.vue';

export default {
  name: 'list-users',

  components: { ModalRegisterUser, About },

  data() {
    return {
      loading: false,
      userRoleType: UserRoleType,
      options: {
        sortBy: ['name'],
        sortDesc: [false],
      },

      totalItems: 0,
      items: [],

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Foto', value: 'avatarUrl', sortable: false, align: 'center', width: 80 },
        { text: 'Nome', value: 'name' },
        { text: 'Permissão', value: 'role' },
        { text: 'Ações', align: 'right', sortable: false, value: 'actions' },
      ],
    };
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },
  },

  methods: {
    async fetch() {
      this.loading = true;

      try {
        const filter = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };

        if (this.options.sortBy.length > 0) {
          filter.sort = `${this.options.sortBy[0]},${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        const { data, meta } = await UsersService.find(filter);
        console.log(data);
        this.items = data;
        this.page = meta?.currentPage;
        this.totalItems = meta?.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    create() {
      this.$refs.modalRegister.$emit('create');
    },

    edit(user) {
      this.$refs.modalRegister.$emit('edit', user.id);
    },

    remove(user) {
      this.$swal({
        text: `Deseja remover '${user.name}'?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        preConfirm: () => {
          return UsersService.delete(user.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Usuário removido com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },

    async reSendInvite(user) {
      this.$swal({
        html: `Deseja re-enviar convite para <strong>${user.email}</strong>`,
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return AuthService.reSendInvite({ email: user.email });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Enviado!',
              text: 'Convite re-enviado com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <v-row class="pb-3">
      <v-col cols="12" sm="4">
        <h5 class="pt-4">Usuários do sistema</h5>
      </v-col>
      <v-col cols="12" sm="8" class="text-right">
        <v-btn color="primary" @click="create">Convidar</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
      class="elevation-1 no-line-row"
    >
      <template v-slot:[`item.avatarUrl`]="{ item }">
        <v-avatar size="80" class="my-2">
          <v-img :src="item.avatarUrl" />
        </v-avatar>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="subtitle-2">{{ item.name }}</div>
        <div>
          <i class="text--secondary">{{ item.email }}</i>
        </div>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        {{ userRoleType[item.role] }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="item.status === 'INACTIVE' && currentUser.id !== item.id"
          icon
          title="Re-enviar convite"
          @click="reSendInvite(item)"
        >
          <v-icon small> mdi-send </v-icon>
        </v-btn>
        <v-btn
          v-if="currentUser.role === 'ADMIN' && currentUser.id !== item.id"
          icon
          title="Remover usuário"
          @click="remove(item)"
        >
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <modal-register-user ref="modalRegister" @success="fetch" />
  </div>
</template>
