import api from './api';

class UsersService {
  get(id) {
    return api.get(`/users/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/users', { params }).then((res) => res.data);
  }

  update(id, data) {
    return api.patch(`/users/${id}`, data).then((res) => res.data);
  }

  delete(id) {
    return api.delete(`/users/${id}`).then((res) => res.data);
  }
}

export default new UsersService();
